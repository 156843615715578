

import {
  IonPage,
  IonButton,
} from "@ionic/vue";


export default {
    components:{
        IonPage,
        IonButton
    },
    setup() {

        const logout = ()=> console.log('out')

        return{
            logout
        }
        
    },
}
